import type { Argument } from 'classnames'
import classnamesBase from 'classnames'
import { overrideTailwindClasses } from 'tailwind-override'

export const isDev =
  process.env['NEXT_PUBLIC_STAGE'] === 'stage' ||
  process.env['NEXT_PUBLIC_ENVIRONMENT'] === 'dev'

export const wait = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms))

export const range = (n: number) => [...Array.from({ length: n }).keys()]

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const makeUnique = (values: string[]) => {
  const newValues = [...new Set(values)]
  return newValues
}

export const keyToUrl = (args: any): string =>
  args
    .filter(
      (arg: any) => (Array.isArray(arg) || typeof arg === 'string') && !!arg,
    )
    .join('/')

export const classnames = (...args: Argument[]) =>
  overrideTailwindClasses(classnamesBase(...args))

export const stringToArrayField = (value: string | string[]) =>
  typeof value === 'string'
    ? value
        .split(',')
        .map(value => value.trim())
        .filter(Boolean)
    : value

export const parseJSONToObject = (data?: string | null) => {
  try {
    if (!data) {
      return
    }
    return JSON.parse(data)
  } catch {
    return data
  }
}

export const getMaxTableHeightWithLoadMore = (offsetHeight: number) => ({
  maxHeight: `calc(100vh - ${offsetHeight ?? 0}px - 248px)`,
})
