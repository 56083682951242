import type { Order, OrderFromTmsFilters } from '@models/order'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

interface UseTmsOrdersProps {
  orders: Order[]
  ordersMap: Map<string, Order>
}

const selectData = (data: Order[]): UseTmsOrdersProps => {
  return {
    orders: data,
    ordersMap: new Map(data.map(order => [order.id, order])),
  }
}

const useTmsOrders = (
  filters: OrderFromTmsFilters,
  refetchCount: () => void,
) => {
  const query = useQuery<Order[], AxiosError, UseTmsOrdersProps>(
    ordersKeys.erpOrders(filters),
    () => ordersApi.getTmsOrders(filters),
    {
      select: selectData,
      onSuccess: () => {
        refetchCount()
      },
    },
  )

  return query
}

export default useTmsOrders
