import type { Order, OrderFromErpFilters } from '@models/order'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

interface UseErpOrdersProps {
  orders: Order[]
  ordersMap: Map<string, Order>
}

const selectData = (data: Order[]): UseErpOrdersProps => {
  return {
    orders: data,
    ordersMap: new Map(data.map(order => [order.id, order])),
  }
}

const useErpOrders = (
  filters: OrderFromErpFilters,
  refetchCount: () => void,
) => {
  const query = useQuery<Order[], AxiosError, UseErpOrdersProps>(
    ordersKeys.erpOrders(filters),
    () => ordersApi.getErpOrders(filters),
    {
      select: selectData,
      onSuccess: () => {
        refetchCount()
      },
    },
  )

  return query
}

export default useErpOrders
