import { waresApi, waresKeys } from '@api'
import { WaresToDepotFilters } from '@models/ware'
import { useQuery } from 'react-query'

const useWaresToDepotCount = (filters: WaresToDepotFilters) => {
  const query = useQuery(
    waresKeys.waresToDepotCount(),
    async () =>
      await waresApi.getWaresToDepotCount({
        ...filters,
        skip: undefined,
        take: undefined,
      }),
  )

  return query
}

export default useWaresToDepotCount
