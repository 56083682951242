;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"baee2d78eb7d570c5022c4ac6560fc9c00284ee7"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs'
import { version } from './package.json'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN
const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT

Sentry.init({
  dsn: SENTRY_DSN,
  release: `v${version}`,
  environment: ENVIRONMENT,
  maxValueLength: 2000,
  enabled: ENVIRONMENT === 'prod',
})

Sentry.setTag('side', 'server')
