import type { Order, OrderFilters } from '@models/order'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

export interface UseOrdersProps {
  orders: Order[]
  ordersMap: Map<string, Order>
}

const selectData = (data: Order[]): UseOrdersProps => {
  return {
    orders: data,
    ordersMap: new Map(data.map(order => [order.id, order])),
  }
}

const useOrders = (filters: OrderFilters, refetchCount?: () => void) => {
  const query = useQuery<Order[], AxiosError, UseOrdersProps>(
    ordersKeys.orders(filters),
    () => ordersApi.getOrders(filters),
    {
      select: selectData,
      enabled: !!filters.companyId,
      onSuccess: () => {
        if (refetchCount) {
          refetchCount()
        }
      },
    },
  )

  return query
}

export default useOrders
