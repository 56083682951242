import { ordersApi, ordersKeys } from '@api'
import type { OrderFilters } from '@models/order'
import { useQuery } from 'react-query'

const useOrdersCount = (filters: OrderFilters) => {
  const query = useQuery(ordersKeys.ordersCount(), async () => {
    const data = await ordersApi.getOrdersCount(filters)
    return data
  })

  return query
}

export default useOrdersCount
