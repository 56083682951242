import Button from '@components/buttons/Button'
import Text from '@components/typography/Text'
import Title from '@components/typography/Title'
import LeftArrow from '@public/icons/left-arrow.svg'
import BurgerIcon from '@public/icons/burger.svg'
import { useRouter } from 'next/router'
import { UrlObject } from 'url'

interface HeaderProps {
  title?: string
  companyName?: string
  headerChildren?: React.ReactNode
  filterSectionChildren?: React.ReactNode
  isBack?: string | UrlObject
  toggleSidebar: () => void
}
const Header = ({
  title,
  companyName,
  headerChildren,
  filterSectionChildren,
  isBack,
  toggleSidebar,
}: HeaderProps) => {
  const router = useRouter()

  if (!title) return null

  return (
    <>
      <header className="sm:p-5 px-2 py-1 sm:h-16 bg-white border-b border-b-main-300 flex items-center justify-between shadow-sm">
        <div className="flex justify-between gap-2 sm:gap-0 flex-wrap sm:flex-row w-full sm:items-center">
          <div className="flex items-center gap-4">
            <Button
              color="grey"
              className="px-0 w-8 max-w-8 min-w-8 h-8 md:hidden"
              onClick={toggleSidebar}
              icon={<BurgerIcon className="w-[18px] h-[18px]" />}
            />

            {isBack && (
              <Button
                color="grey"
                className="px-0 w-8 max-w-8 min-w-8 h-8"
                onClick={() => (isBack ? router.push(isBack) : router.back())}
                icon={<LeftArrow className="w-[18px] h-[18px]" />}
              />
            )}

            <div className="flex items-start flex-col">
              <Text as="p" size="caption-small" className="text-main-900">
                {companyName}
              </Text>
              {title && (
                <Title as="h4" className="text-primary">
                  {title}
                </Title>
              )}
            </div>
          </div>

          {headerChildren}
        </div>
      </header>
      {filterSectionChildren && (
        <div className="px-2 sm:px-5 sm:py-2 bg-white border-b border-b-main-300">
          <div>{filterSectionChildren}</div>
        </div>
      )}
    </>
  )
}

export default Header
