import { routesApi, routesKeys } from '@api'
import { RouteFilters } from '@models/route'
import { useQuery } from 'react-query'

const useRoutesCount = (filters: RouteFilters) => {
  const query = useQuery(
    routesKeys.routesCount(),
    async () =>
      await routesApi.getRoutesCount({
        ...filters,
        take: undefined,
        skip: undefined,
      }),
  )

  return query
}

export default useRoutesCount
